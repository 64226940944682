<template>
	<v-container fluid>
		<v-row>
			<v-col v-if="memories.length < 1" cols="12" lg="6" xl="4" class="mx-auto">
				<p class="text-center mt-15">No memories</p>
			</v-col>

			<v-col v-else cols="12" sm="6" md="4" lg="3" v-for="(memory, i) in memories">
				<r-memory-tile
					:thumbnail="memory.thumbnail"
					:title="memory.title"
					:shared="memory.is_shared"
					:to="{name: 'ShowMemory', params: {id: memory.id}}"
					:icon="memory.icon"
				></r-memory-tile>
			</v-col>

		</v-row>
	</v-container>
</template>

<script>

import api from "@/services/api";
import _sample from 'lodash/sample'
import {sync} from "vuex-pathify";

export default {
	name: 'Shared',
	props: ['friend_id'],
	computed: {
		title: sync('app/title'),
	},
	mounted() {
		this.getMemories()
		this.title = 'Shared'
	},
	data: () => ({
		memories: [],
	}),
	methods: {
		getMemories() {
			this.$api.shared_memories.index(this.friend_id)
				.then(response => {
					this.memories = response.data
				})
				.catch(error => console.log(error))
		},
	}
}
</script>
